/**@jsx jsx */
import { jsx } from 'theme-ui';
import { Link } from 'gatsby';
import Layout from '../../components/layout';

const Posts = ({ data }) => {
  const posts = data.allBlogPost.edges.map(({ node }) => node);
  return (
    <Layout>
      {posts.map((post) => {
        console.log('post', post.title);
        return (
          <article key={post.id} sx={{ mt: 5 }}>
            <h2>
              <Link to={post.slug}>{post.title}</Link>
            </h2>
            <p>{post.excerpt}</p>
          </article>
        );
      })}
    </Layout>
  );
};

export default Posts;
